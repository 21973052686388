.pr-text-input {
  position: relative;
  display: block;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.pr-text-input__input {
  display: block;
  max-width: 100ch;
  height: 40px;
  padding-right: var(--spacing-l);
  padding-left: var(--spacing-l);
  border: 1px solid var(--n300);
  border-radius: var(--border-radius-m);
  color: var(--n700);
  background-color: var(--n0);
  font-family: var(--font-family);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);

  &:focus {
    border-color: var(--b100);
    outline: none;
    box-shadow: 0 0 3px var(--b100);
  }

  &:disabled,
  &[readonly] {
    color: var(--n400);
    background-color: var(--n100);
    cursor: default;

    & + .pr-text-input__clipboard-button {
      border-color: var(--n100);
    }
  }

  &::placeholder {
    color: var(--n400);
    font-size: var(--font-size-m);
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }
}

textarea.pr-text-input__input {
  padding-top: var(--spacing-m);
  padding-bottom: var(--spacing-m);
  line-height: var(--line-height);
  height: 50px;
}
