@import 'styles/main';

body {
  margin: 0;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(-45deg, #0b0133, #140935, #1b2c45, #27245b);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}

html {
  height: 100%;
  background-color: #000;
}

div#root {
  height: 100%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
