@import './src/styles/variables';

.pr-heading {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  line-height: 1.3;
  text-align: left;
  color: var(--n900);
  margin: var(--spacing-s);


  & > span {
    display: block;
    margin-top: var(--spacing-xs);
    color: var(--n900);
    max-width: 60ch;
    font-style: italic;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-l);
  }
}

.pr-heading--centered {
  text-align: center;
}

h1.pr-heading {
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
}

h2.pr-heading {
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
}

h3.pr-heading {
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
}

h4.pr-heading {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  line-height: 1.4;
}

.pr-heading--bordered {
  padding: var(--spacing-m);
  border-bottom: var(--n100) 2px solid;
  background-color: transparent;
}

.pr-heading--light {
  color: var(--n0);

  & > span {
    color: rgba(255, 255, 255, 0.64);
  }
}


