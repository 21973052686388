

.snack-unsubscribe-topic {

  .fancy-w{
    font-family: 'Press Start 2P',serif;
    color: white;
  }

  .fancy{
    font-family: 'Press Start 2P',serif;
    animation: color-change 10s alternate infinite;
    text-shadow: 1px 1px #fff7f5;
  }

  @keyframes color-change {
    0% { color: #d2c183; }
    50% { color: #d2a655; }
    100% { color: #f09373; }
  }

  .dropdown {

    height: 44px;
    margin-right: 4px;
    border-radius: var(--border-radius-m);
    color: var(--n700);
    width: 130px;
    background-color: var(--n0);
    font-family: var(--font-family);
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-semibold);
  }

  .placeholder{
    margin-top: 2px;
    font-weight: var(--font-weight-semibold);
  }

  .arrow {
    margin-top: 6px;
  }

  .horizontal {
    display: flex;
    flex-direction: row;
  }
}
