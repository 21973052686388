.mb-xs {
  margin-bottom: var(--spacing-xs);
}

.mb-s {
  margin-bottom: var(--spacing-s);
}

.mb-m {
  margin-bottom: var(--spacing-m);
}

.mt-m {
  margin-top: var(--spacing-m);
}

.p-l {
  padding: var(--spacing-l)
}

.p-m {
  padding: var(--spacing-m)
}
