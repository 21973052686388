.pr-button {
  display: inline-flex;
  min-width: 80px;
  height: 60px;
  padding-right: var(--spacing-xl);
  padding-left: var(--spacing-xl);
  justify-content: center;
  align-items: center;
  border: 2px solid var(--n900);
  color: var(--n0);
  background-color: var(--p600);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  border-radius: var(--border-radius-xl);


  &:hover:not(:disabled) {
    opacity: 0.9;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--n0), 0 0 0 4px rgba(59, 17, 117, 0.3);
  }

  &:disabled {
    border-color: var(--n600);
    background-color: var(--n600);
    cursor: default;
  }

  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.pr-button--ondark{
  background-color: transparent;
  color: white;
  border: 0px;
}

.pr-button--fancy {
  position: relative;
  background-color: var(--n0);

  &:after {
    position: absolute;
    z-index: -1;
    top: 1px;
    left: 1px;
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    border: 2px solid var(--primary);
    content: '';
  }

  &:hover:not(:disabled) {
    color: var(--n0);
  }
}

.pr-button--bordered {
  border: var(--n100) 1px solid;
  border-radius: var(--border-radius-xl);
  background-color: transparent;
}


.pr-button--primary {
  border-color: var(--primary);
  background-color: var(--primary);

  &:hover:not(:disabled) {
    border-color: var(--primary);
  }
}

.pr-button--secondary {
  color: var(--n700);
  background-color: transparent;
  border-color: var(--g200);
  color: var(--n0);

  &:hover:not(:disabled) {
    background-color: var(--n600);
  }
}

.pr-button--link {
  min-width: 0;
  border-color: lightblue;
  color: var(--n0);
  background-color: transparent;

  &:hover:not(:disabled) {
    border-color: var(--n50);
    background-color: var(--n50);
  }

  &.pr-button--primary {
    color: var(--primary);
  }

  &.pr-button--secondary {
    color: var(--n700);
  }
}

.pr-button--inline-link {
  min-width: 0;
  height: auto;
  padding-right: 0;
  padding-left: 0;
  border: none;
  color: var(--n900);
  background-color: transparent;

  &:hover:not(:disabled) {
    color: var(--primary);
    background-color: transparent;
  }

  &.pr-button--primary {
    color: var(--primary);
  }

  &.pr-button--secondary {
    color: var(--n700);
  }

  &.pr-button--light {
    color: var(--n0);

    &:hover {
      color: var(--n0);
    }
  }
}
