.tippy-tooltip {
  border-radius: var(--border-radius-m);
  font-size: var(--font-size-s);
  z-index: 10000;

  & [x-circle] {
    background-color: var(--n900);
  }

  & a {
    color: var(--n0);
  }
}
