
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');

@import 'variables';
@import 'vendor';

*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  color: var(--n900);
  font-family: 'Open Sans',serif;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-regular);
  line-height: 1.5;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

strong {
  font-weight: var(--font-weight-bold);
}

p {

}

input,
button,
textarea,
select {
  font: inherit;
}
