@import './src/styles/variables';

.pr-p{
  display: block;
  margin: 0px;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-regular);
  text-align: left;
  line-height: 24px;
  max-width: 49ch;
  color: var(--n900);

}

.pr-p--error{
  color: var(--r300)
}

.pr-p--success{
  color: var(--g700)
}

.pr-p--tiny{
  font-weight: var(--font-weight-font-weight-thin);
  color: var(--n500);
  font-style: italic;
  font-size: var(--font-size-s);
}

.pr-p--light{
  font-weight: var(--font-weight-font-weight-thin);
  color: var(--n500);
  font-size: var(--font-size-s);
}

.pr-p--bold{
  font-weight: var(--font-weight-bold);
}

.pr-p--white{
  color: var(--n0);
}

.pr-p--center{
  text-align: center;
}

.pr-p--transparent{
  opacity: 0.7;
}

.pr-p--small{
  font-size: var(--font-size-s);
}
