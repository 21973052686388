@import './src/styles/variables';

.pr-claim {
  font-size: var(--font-size-6xl);
  font-weight: var(--font-weight-semibold);
  line-height: 1.3;
  text-align: center;
  color: white;

span {
  color: var(--y500);
  font-weight: var(--font-weight-bold);
}

  h1 {
    font-size: var(--font-size-4xl);
    max-width: 20ch;

  }
}
