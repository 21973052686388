/**
* @tokens Border Radius
* @presenter BorderRadius
*/

:root {
  --border-radius-m: 3px;
  --border-radius-l: 6px;
  --border-radius-xl: 9px;
}

/**
* @tokens Colors
* @presenter Color
*/

:root {
  --n0: #fff;
  --n50: #f4f5f7;
  --n100: #dcdee4;
  --n200: #c4c8d1;
  --n300: #c3c7d0;
  --n400: #a3a9b7;
  --n500: #838b9e;
  --n600: #636d86;
  --n700: #44506d;
  --n800: #243254;
  --n900: #04143b;

  --b50: #eaf6fc;
  --b100: #cfecf8;
  --b200: #b5e1f4;
  --b300: #9bd7f0;
  --b400: #81cced;
  --b500: #66c2e9;
  --b600: #4cb7e5;
  --b700: #3e96bc;
  --b800: #317593;
  --b900: #235469;

  --r50: #fed8df;
  --r100: #fbb9c4;
  --r200: #f89bab;
  --r300: #f37e92;
  --r400: #ed627a;
  --r500: #e54863;
  --r600: #c23a51;
  --r700: #9e2d40;
  --r800: #7a2130;
  --r900: #541520;

  --g50: #eefbf2;
  --g500: #91e7b0;
  --g600: #30ca66;
  --g700: #2bb254;

  --y50: #fff9ed;
  --y500: #ffc11e;

  --p50: #f1eef5;
  --p100: #d3cae0;
  --p200: #b5a5cb;
  --p300: #9780b5;
  --p400: #795ca0;
  --p500: #5a378a;
  --p600: #3c1275;
  --p700: #030303;
  --p800: #2d0e58;
  --p900: #260b49;

  --primary: var(--y500);
  --primary-light: var(--p500);

  --error: var(--r500);
}

/**
* @tokens Durations
*/

:root {
  --duration-s: 0.2s;
}

/**
* @tokens Font Families
* @presenter FontFamily
*/

:root {
  --font-family: 'Open-Sans', sans-serif;
}

/**
* @tokens Font Sizes
* @presenter FontSize
*/

:root {
  --font-size-xs: 1.0rem;
  --font-size-s: 1.2rem;
  --font-size-m: 1.4rem;
  --font-size-l: 1.6rem;
  --font-size-xl: 2.1rem;
  --font-size-xxl: 2.4rem;
  --font-size-3xl: 3.2rem;
  --font-size-4xl: 4.8rem;
  --font-size-5xl: 7.2rem;
  --font-size-6xl: 8.2rem;

}

/**
* @tokens Font Weights
* @presenter FontWeight
*/

:root {
  --font-weight-thin: 100;
  --font-weight-regular: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 800;
}

/**
* @tokens Spacings
* @presenter Spacing
*/

:root {
  --spacing-xs: 4px;
  --spacing-s: 8px;
  --spacing-m: 12px;
  --spacing-l: 16px;
  --spacing-xl: 20px;
  --spacing-xxl: 24px;
  --spacing-3xl: 36px;
  --spacing-4xl: 56px;
  --spacing-5xl: 96px;
}

/**
* @tokens Z-Index
*/

:root {
  --z-index-modal: 500;
}
