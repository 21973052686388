.pr-module {
}

.padded {
  padding: 2vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.pr-module--align-center {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pr-module--align-right {
  text-align: right;
}

.pr-module--horizontal {
  flex-direction: row;
  display: flex;
}

.pr-module--spacing-l {
  &:not(:last-child) {
    margin-bottom: var(--spacing-l);
  }

  &.pr-module--bordered {
    padding-bottom: var(--spacing-m);
    border-bottom: 2px dotted var(--p500);
    border-radius: var(--border-radius-xl);
  }
}

.pr-module--spacing-xl {
  &:not(:last-child) {
    margin-bottom: var(--spacing-xl);
  }

  &.pr-module--bordered {
    padding-bottom: var(--spacing-xl);
    border-bottom: 1px solid var(--n100);
  }
}

.pr-module--spacing-xxl {
  &:not(:last-child) {
    margin-bottom: var(--spacing-xxl);
  }

  &.pr-module--bordered {
    padding-bottom: var(--spacing-xxl);
    border-bottom: 1px solid var(--n100);
  }
}

.pr-module--spacing-3xl {
  &:not(:last-child) {
    margin-bottom: var(--spacing-3xl);
  }

  &.pr-module--bordered {
    padding-bottom: var(--spacing-3xl);
    border-bottom: 1px solid var(--n100);
  }
}

.pr-module--spacing-4xl {
  &:not(:last-child) {
    margin-bottom: var(--spacing-4xl);
  }

  &.pr-module--bordered {
    padding-bottom: var(--spacing-4xl);
    border-bottom: 1px solid var(--n100);
  }
}

.pr-module--spacing-5xl {
  &:not(:last-child) {
    margin-bottom: var(--spacing-5xl);
  }

  &.pr-module--bordered {
    padding-bottom: var(--spacing-5xl);
    border-bottom: 1px solid var(--n100);
  }
}
