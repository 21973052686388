.switch {
  position: relative;
  display: block;
  vertical-align: top;
  min-width: 120px;
  max-width: 150px;
  height: 30px;
  padding: 3px;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
  border-radius: var(--border-radius-xl);
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  box-sizing:content-box;
}
.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-sizing:content-box;
}
.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: var(--font-size-s);
  text-transform: uppercase;
  background: #eceeef;
  border-radius: var(--border-radius-l);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  box-sizing:content-box;
}
.switch-label:before, .switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
  box-sizing:content-box;
}
.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #aaaaaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: #FFFFFF;
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
  background: var(--g600);
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}
.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 28px;
  height: 28px;
  background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
  border-radius: 100%;
}
.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
  border-radius: var(--border-radius-m);
}
.switch-input:checked ~ .switch-handle {
  left: 75%;
}

/* Transition
========================== */
.switch-label, .switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

.switch-light {
  padding: 0;
  background: #FFF;
  background-image: none;
}
.switch-light .switch-label {
  background: #FFF;
  box-shadow: none;
}
.switch-light .switch-label:after {
  color: #FFF;
}
.switch-light .switch-label:before {
  right: inherit;
  left: 11px;
}
.switch-light .switch-handle {
  left: 75%;
  background: #dadada;
  width: 22px;
  height: 22px;
  box-shadow: none;
}
.switch-light .switch-handle:before {
  background: var(--r500);
}
.switch-light .switch-input:checked ~ .switch-label {
}
.switch-light .switch-input:checked ~ .switch-handle {
  left: 75%;
  box-shadow: none;
  background: var(--g700)
}
.switch-light .switch-input:checked ~ .switch-handle:before {
  background: var(--g50);
}
