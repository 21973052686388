@import './src/styles/variables';

.pr-label{
  display: block;
  margin-top: var(--spacing-xs);
  color: var(--n0);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  text-align: left;
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-m);
}
